import React, { useState, useEffect } from 'react';

const ClassicSixHowItWorks = ({ lang }) => {
  // const [transferCode, setTransferCode] = useState(null);
  const copyStrings = {
    en: {
      heading: 'HOW DOES THIS WORK?',
    },
  };

  const instructions = {
    en: [
      {
        id: 1,
        title: 'Join',
        description: 'Simply sign up by entering your email address above! No password required!',
      },
      {
        id: 2,
        title: 'Check your email',
        description: 'You’ll get an email from us with your magic login link. Click through to validate your account and start exploring your membership!',
      },
      {
        id: 3,
        title: 'Start earning',
        description: 'Every time you shop and share, you can earn points that turn into cash rewards, get perks like early access to sales, new collections and contests, and view exclusive styling and behind the scenes content you won’t find anywhere else!',
      },
    ],
  };

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div className="how-it-works__wrapper">
      <div id="startContent" className="how-it-works">
        <div className="how-it-works__title">
          {copyStrings[lang].heading}
        </div>
        <div className="how-it-works__list">
          {instructions[lang].map((step, index) => (
            <div key={step.id} className="how-it-works__item">
              <div className="how-it-works__number">{index + 1}</div>
              <div className="how-it-works__step-title">{step.title}</div>
              <div className="how-it-works__step-description">{step.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default ClassicSixHowItWorks;
