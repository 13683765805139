import { set } from 'lodash';
import React, { useState, useEffect } from 'react';

const ClassicSixTiers = ({ lang, expandTiers }) => {
  const [showTiers, setShowTiers] = useState(true);
  const [hideExpander, setHideExpander] = useState(false);

  const copyStrings = {
    en: {
      heading: 'WHAT’S MY CIRCLE TIER?',
      tableHeading: 'Benefits',
      tableColHeader1: 'Timeless',
      tableColDesc1: '0-500 Points',
      tableColHeader2: 'Elegant',
      tableColDesc2: '501-1000 Points',
      tableColHeader3: 'Heritage',
      tableColDesc3: '1001 points+',
      tableRowDesc1: 'Exclusive styling and behind the scenes content',
      tableRowDesc2: 'Early access to sales & new collections',
      tableRowDesc3: 'Entrance to member-only contests',
      tableRowDesc4: 'Free Shipping',
      tableRowDesc5: 'Private access to online warehouse sales',
      tableRowDesc6: 'VIP Invitations to in-store events',
    },
  };

  const toggleTiers = () => {
    setShowTiers(!showTiers);
  };

  useEffect(() => {
    // on mount
    if (expandTiers) {
      setShowTiers(true);
      setHideExpander(true);
    }
  }, []);

  return (
    <div className={!hideExpander ? 'custom-tiers__breakdown' : 'custom-tiers__breakdown -slim'}>
      <div className={!hideExpander ? 'custom-tiers__inner' : 'custom-tiers__inner -slim'}>
        {!hideExpander
        && (
        <div className="custom-tiers__heading" onClick={(e) => toggleTiers()}>
          {copyStrings[lang].heading}
          <a><img className={showTiers ? '-rotate' : ''} src="https://assets.heyethos.com/bonlook/landing/icon-down-arrow.png" alt="What’s my Trendsetter tier?" /></a>
        </div>
        )}
        {showTiers
        && (
        <div className={!hideExpander ? 'custom-tiers__table' : 'custom-tiers__table -slim'}>
          <table>
            <tr>
              <th>{copyStrings[lang].tableHeading}</th>
              <th>
                {copyStrings[lang].tableColHeader1}
                <span>{copyStrings[lang].tableColDesc1}</span>
              </th>
              <th>
                {copyStrings[lang].tableColHeader2}
                <span>{copyStrings[lang].tableColDesc2}</span>
              </th>
              <th>
                {copyStrings[lang].tableColHeader3}
                <span>{copyStrings[lang].tableColDesc3}</span>
              </th>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc1}</td>
              <td>&middot;</td>
              <td>&middot;</td>
              <td>&middot;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc2}</td>
              <td>&middot;</td>
              <td>&middot;</td>
              <td>&middot;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc3}</td>
              <td>&middot;</td>
              <td>&middot;</td>
              <td>&middot;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc4}</td>
              <td></td>
              <td>&middot;</td>
              <td>&middot;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc5}</td>
              <td></td>
              <td></td>
              <td>&middot;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc6}</td>
              <td></td>
              <td></td>
              <td>&middot;</td>
            </tr>
          </table>
        </div>
        )}
      </div>
    </div>
  );
};
export default ClassicSixTiers;
