/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import SimpleModal from '../modal/modal';
import ClassicSixTiers from './classicSixTiers';
import AccountManager from '../../managers/Account';
import RedemptionsManager from '../../managers/Redemptions';

function ClassicSixRedemptionCenter({
  tier, assetId, features, readOnly,
}) {
  const [announcements, setAnnouncements] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [membershipFeatures, setMembershipFeatures] = useState([]);
  const [redemptionsList, setRedemptionsList] = useState([]);

  useEffect(() => {
    const availableFeatures = [];
    if (features && features.length > 0) {
      features.forEach((feature) => {
        availableFeatures.push(feature.featureName);
      });
      setMembershipFeatures(availableFeatures);
    }
  }, [features]);

  const getRedemptionsList = async () => {
    const redemptionsArray = [];
    try {
      const redemptions = await RedemptionsManager.getRedemptions(assetId, AccountManager.getToken());
      if (redemptions && redemptions.success) {
        // setIsLoading(false);
        if (redemptions.redemptionConfigurations && redemptions.redemptionConfigurations.length > 0) {
          redemptions.redemptionConfigurations.forEach((redemption) => {
            if (redemption.membershipTiers.length === 0) {
              redemptionsArray.push(redemption);
            } else if (redemption.membershipTiers.includes(tier.assetVariationId)) {
              redemptionsArray.push(redemption);
            }
          });
          setRedemptionsList(redemptionsArray);
        }
      }
    } catch (error) {
      // setIsLoading(false);
      toast.error(error ? error.message : 'An error occurred while getting your redemptions. Please try again later.');
    }
  };

  useEffect(() => {
    if (membershipFeatures.includes('Redemption')) {
      getRedemptionsList();
    }
  }, [membershipFeatures]);

  return (
    <div className="redemption-center">
      <SimpleModal wideMode isOpen={showModal} onBeforeClose={() => setShowModal(false)}>
        <>
          <ClassicSixTiers lang="en" expandTiers />
        </>
      </SimpleModal>
      <div className="benefits-legend">
        The more you earn, the quicker you level up
        <div className="benefits-highlighted__cta">
          <button
            type="button"
            className="button-collection"
            onClick={() => setShowModal(true)}>
            The levels
          </button>
        </div>
      </div>
      {redemptionsList && redemptionsList.length > 0 && (
        <>
          <div className="benefits-summary__header">
            <div className="benefits-summary__header--title">
              Redeem Points
            </div>
          </div>
          <div className="benefits-summary__vouchers">
            <div className="benefits-summary__vouchers--header">Redeeming your points is super easy. Click on the box to the right that says “Redeem Points”</div>
            <div className="benefits-highlighted">
              {redemptionsList.map((redemption, i) => (
                <div
                  className="benefits-highlighted__item"
                  key={redemption.uniqueId}>
                  <div className="benefit-voucher__price">
                    <strong>
                      $
                      {redemption.value}
                    </strong>
                    voucher
                  </div>
                  <div className="benefit-voucher__points">
                    {redemption.cost}
                    {' '}
                    points
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ClassicSixRedemptionCenter;
