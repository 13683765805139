import React, { Component } from 'react';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSticky: false,
    };
  }

  setStickyNav = (url) => {
    if (url.includes('/account/')
      || url.includes('/login')
      || url.includes('/register')) {
      this.setState({
        isSticky: true,
      });
    } else {
      this.setState({
        isSticky: false,
      });
    }
  }

  componentDidMount() {
    if (window.location.href.includes('/account/')
      || window.location.href.includes('/login')
      || window.location.href.includes('/register')) {
      this.setState({
        isSticky: true,
      });
    } else {
      this.setState({
        isSticky: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { url } = this.props;
    if (url !== prevProps.url) {
      this.setStickyNav(url);
      console.log('update sticky nav');
    }
  }

  render() {
    const { isSticky } = this.state;
    return (
      <div className={isSticky ? 'footer -fixed' : 'footer'}>
        <div className="footer-inner">
          {process.env.SMART_CONTRACT
            ? (
              <div>
                View smart contract on
                {' '}
                <a href={process.env.SMART_CONTRACT} target="_blank" rel="noreferrer">{process.env.SMART_CONTRACT_CHAIN}</a>
                .
              </div>
            )
            : <div>&nbsp;</div>}
          <div className="footer-inner__right">
            Powered by
            {' '}
            <a href="https://www.heyethos.com" target="_blank" rel="noreferrer">ethos</a>
            .
            {/* {process.env.CREATED_BY_NAME
              && (
              <span>
                &nbsp;|&nbsp;Created by
                {' '}
                <a href={process.env.CREATED_BY_LINK} target="_blank" rel="noreferrer">{process.env.CREATED_BY_NAME}</a>
                .
              </span>
              )} */}
          </div>
          
        </div>
      </div>
    );
  }
}

export default Footer;
