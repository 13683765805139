import '../../config/flow';
import * as fcl from '@onflow/fcl';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment-mini';
import SimpleModal from '../modal/modal';
import FlowWallet from './flowWallet';
import MetamaskWallet from '../wallet/metamaskWallet';
import AccountManager from '../../managers/Account';
import Throbber from '../throbber';
import { getBlockchainType, getScanUrl, getScanName } from '../../managers/Helpers';

class TransferWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      walletAddress: null,
      isLoading: false,
      transferText: '',
      transferSuccess: false,
      transactionUrl: process.env.BLOCKCHAIN_ENVIRONMENT === 'test' ? 'https://testnet.flowscan.org/transaction/' : 'https://flowscan.org/transaction/',
      polyscanUrl: process.env.BLOCKCHAIN_ENVIRONMENT === 'test' ? 'https://mumbai.polygonscan.com/' : 'https://polygonscan.com/',
      transactionId: null,
      blockcainType: null,
    };
  }

  getGenerateCollectionScript = () => {
    const { nft } = this.props;
    const contractName = 'ethos' + nft.asset.name.replace(/\s/g, '');

    const setupScript = `
      import ${contractName} from 0xEthosDeployer
      import NonFungibleToken from 0xFlowStandards
      import MetadataViews from 0xFlowStandards
      
      transaction() {
        prepare(signer: AuthAccount) {
          if signer.borrow<&${contractName}.Collection>(from: ${contractName}.CollectionStoragePath) == nil {
            signer.save(<- ${contractName}.createEmptyCollection(), to: ${contractName}.CollectionStoragePath)
            signer.link<&${contractName}.Collection{NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(${contractName}.CollectionPublicPath, target: ${contractName}.CollectionStoragePath)
          }
        }
      }
    `;
    return setupScript;
  }

  initTransfer = async () => {
    const { walletAddress } = this.state;
    const { nft, onSuccess } = this.props;
    const payload = {
      nftId: nft.nftId,
      walletAddress,
    };
    this.setState({
      transferText: `Transferring NFT to ${walletAddress}.`,
      isLoading: true,
    });
    const transferResult = await AccountManager.transferToWallet(AccountManager.getToken(), payload);
    if (transferResult && transferResult.success) {
      toast.success('Successfully transferred NFT to your wallet');
      onSuccess(walletAddress);
      this.setState({
        transferSuccess: true,
        transactionId: transferResult.transactionId,
      });
      gtag('event', process.env.CREATOR_SUBDOMAIN + '_nft_transfer', { // eslint-disable-line
        event_category: 'MEMBERSHIP_TRANSFER_success',
        event_label: 'User transferred NFT with the transaction id: ' + transferResult.transactionId,
      });
    } else {
      toast.error('Oops! Something went wrong. Please try again later.');
      gtag('event', process.env.CREATOR_SUBDOMAIN + '_nft_transfer', { // eslint-disable-line
        event_category: 'MEMBERSHIP_TRANSFER_failed',
        event_label: 'User transferred an NFT but it failed. nftId: ' + nft.nftId,
      });
    }
    
    this.setState({
      isLoading: false,
    });
  }

  createCollection = async () => {
    const { walletAddress } = this.state;
    const { nft } = this.props;
    const collectionName = 'ethos' + nft.asset.name.replace(/\s/g, '');
    const that = this;
    const { name } = nft.drop;
    this.setState({
      transferText: 'Looking for collection. Stand by...',
      isLoading: true,
    });
    try {
      const txId = await fcl.mutate({
        cadence: this.getGenerateCollectionScript(),
        args: (arg, t) => [],
        payer: fcl.authz,
        proposer: fcl.authz,
        authorizations: [fcl.authz],
        limit: 9999,
      });

      fcl.tx(txId).subscribe((txStatus) => {
        let loaderText = null;
  
        switch (txStatus?.status) {
          case 1:
            loaderText = `Setting up ${walletAddress} with ${collectionName} collection...`;
            break;
          case 2:
            loaderText = `Setting up ${walletAddress} with ${collectionName} collection...`;
            break;
          case 3:
            loaderText = `Setting up ${walletAddress} with ${collectionName} collection...`;
            break;
          case 4:
            loaderText = `Setting up ${walletAddress} with ${collectionName} collection...`;
            break;
          default:
            loaderText = `Setting up ${walletAddress} with ${collectionName} collection...`;
        }
  
        that.setState({
          transferText: loaderText,
        });
  
        // console.log("txStatus", txStatus);
        if (txStatus?.status === 4) {
          if (txStatus.statusCode === 0) {
            that.setState({
              transferText: `Transferring NFT to ${walletAddress}.`,
            });
            console.log(
              `Successfully setup ${walletAddress} with ${collectionName} collection!`,
            );
  
            // initiate transfer now? Or wait to make the user click something
            // call our API
            that.initTransfer();
          } else {
            const { errorMessage } = txStatus;
            const contractLineNumber = errorMessage
              .split(' --> ')[1]
              .split(':')[1];
            that.setState({
              isLoading: false,
            });
            toast.error(
              `Error setting up ${walletAddress} with ${collectionName} collection. Check line ${contractLineNumber} of your script.`,
            );
            console.log('Contract deployment failed!');
          }
        }
      });
    } catch (e) {
      that.setState({
        isLoading: false,
      });
      console.log(e);
    }
  };

  updateAddress = (user) => {
    console.log('=== updateAddress ===');
    console.log(user);
    if (user && user.addr) {
      this.setState({
        walletAddress: user.addr,
      });
    } else {
      this.setState({
        walletAddress: null,
      });
    }
  }

  updateWallet = async (user) => {
    const { isLoggingIn } = this.state;
    const payload = {
      blockchain: 'flow',
      walletType: 'blocto',
      walletAddress: null,
    };
    if (isLoggingIn) {
      payload.walletAddress = user.addr;
      await AccountManager.addWallet(payload, AccountManager.getToken());
    }
  }

  onLogInAttempt = () => {
    this.setState({
      isLoggingIn: true,
    });
  }

  updateEthereumWallet = async (user) => {
    const { nft } = this.props;
    const payload = {
      blockchain: nft.asset.blockchain === 1 ? 'ethereum' : 'polygon',
      walletType: 'metamask',
      walletAddress: user.addr,
    };

    await AccountManager.addWallet(payload, AccountManager.getToken());
    if (user && user.addr) {
      this.setState({
        walletAddress: payload.walletAddress,
      });
    }
  }

  componentDidMount() {
    const { nft } = this.props;
    this.setState({
      blockcainType: getBlockchainType(nft.asset.blockchain),
    });
  }

  render() {
    const {
      blockcainType, transactionUrl, polyscanUrl, transactionId, transferSuccess, walletAddress, isLoading, transferText,
    } = this.state;
    const { nft, onClose } = this.props;
    return (
      <div>
        <SimpleModal isOpen onBeforeClose={() => onClose()}>
          {isLoading
            && <Throbber throbberText={transferText} />}
          {!transferSuccess
          && <div className="account-heading">Transfer to your wallet</div>}
          {/* <p>You can use this feature to transfer an NFT to your own crypto wallet.</p> */}
          {!nft.contractAddress
            && (
            <div className="transfer-description">
              <p>This digital asset cannot be transferred to your wallet because it has not been minted yet. There are a few reasons why this may happen:</p>
              <ul className="">
                <li>This is a pre-sale purchase which has as a minted date schedule in the future.</li>
                <li>This digital asset is in the queue and is in progress of being minted.</li>
                <li>The blockchain network is unavailable.</li>
              </ul>
              <p>
                Feel free to contact our support team if you require further details at
                {' '}
                <a href="mailto:support@ethosnft.com">support@ethosnft.com</a>
              </p>
            </div>
            )}

          {nft.contractAddress
            ? (
              <div>
                {walletAddress
                  ? (
                    <div>
                      {transferSuccess
                        ? (
                          <div className="transfer-success register-results">
                            <img className="register-results__icon" src="https://assets.heyethos.com/ethos/icon-success.svg" alt="" />
                            <div className="register-results__header">Transfer successful!</div>
                            <p>This transaction is currently being processed on the blockchain. It may take some time before it appears in your wallet.</p>
                            {getBlockchainType(nft.asset.blockchain) === 'Polygon' || getBlockchainType(nft.asset.blockchain) === 'Ethereum'
                              ? (
                                <div>
                                  <p>
                                    You can view the status of this transaction on
                                    {' '}
                                    <a href={getScanUrl(nft)} target="_blank" rel="noreferrer">{getScanName(nft.asset.blockchain)}</a>
                                    {' '}
                                    for updates and more information.
                                  </p>
                                  <div className="transfer-success__instructions">
                                    <h4>To manually add this NFT to your Metamask mobile app:</h4>
                                    Network:
                                    {' '}
                                    {getBlockchainType(nft.asset.blockchain)}
                                    {' '}
                                    Mainnet
                                    <br />
                                    Contract Address:
                                    {' '}
                                    {nft.asset.contractAddress}
                                    <br />
                                    Token ID:
                                    {' '}
                                    {nft.number || nft.tokenId}
                                  </div>
                                </div>
                              )
                              : (
                                <p>
                                  You can view the status of this transaction on
                                  {' '}
                                  <a href={transactionUrl + transactionId} target="_blank" rel="noreferrer">flowscan</a>
                                  {' '}
                                  for updates and more information.
                                </p>
                              )}
                          </div>
                        )
                        : (
                          <div className="transfer-preview">
                            <div className="transfer-preview__image">
                              {nft.imageUrl.includes('.mp4')
                                ? (
                                  <video autoPlay="autoplay" loop muted>
                                    <source src={nft.imageUrl || nft.asset.mediaMP4} type="video/mp4" />
                                    <track
                                  default
                                  kind="captions"
                                  srcLang="en"
                                  src="" />
                                    Your browser does not support the video tag.
                                  </video>
                                )
                                : <img src={nft.imageUrl || nft.asset.mediaThumbnailUrl || nft.asset.mediaLocalIPFS} alt="NFT Preview" />}
                              
                            </div>
                            <div className="transfer-preview__details">
                              <div className="transfer-preview__details--inner">
                                <h3
                                className="card-title"
                                dangerouslySetInnerHTML={{ __html: nft.assetVariation && nft.assetVariation.variationName ? nft.assetVariation.variationName : nft.asset.name }}>
                                </h3>
                                <p>
                                  Token ID:
                                  {' '}
                                  {nft.number || nft.tokenId}
                                </p>
                                <p>
                                  Blockchain:
                                  {' '}
                                  {getBlockchainType(nft.asset.blockchain)}
                                </p>
                                <p>
                                  Date minted:
                                  {' '}
                                  {moment(nft.dateMinted).format('MMMM DD, YYYY')}
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      {!transferSuccess
                      && (
                      <p>
                        Would you like to transfer this digital asset to the wallet with address:
                        &nbsp;
                        <strong className="transfer-preview__wallet-address">{walletAddress}</strong>
                      </p>
                      )}
                    </div>
                  )
                  : (
                    <div>
                      <p>
                        No
                        {' '}
                        {blockcainType}
                        {' '}
                        supported wallet detected. To transfer this digital asset to a wallet, please connect one.
                      </p>
                      {getBlockchainType(nft.asset.blockchain) === 'Polygon' || getBlockchainType(nft.asset.blockchain) === 'Ethereum'
                        ? (
                          <p className="transfer-preview__mobile--note">
                            Note: If you are on a Mobile device, you will need to use the Metamask browser from inside the Metamask mobile app.
                            <br />
                            {' '}
                            Instructions for this can be found on the
                            {' '}
                            <a href="https://support.metamask.io/hc/en-us/articles/6356387482523-How-to-use-the-MetaMask-Mobile-Browser" target="_blank" rel="noreferrer">Metamask support site</a>
                            .
                          </p>
                        )
                        : (
                          <span></span>
                        )}
                    </div>
                  )}
                {/* <p>
                  Transferring this NFT to the following wallet address:&nbsp;
                  {walletAddress}
                </p> */}
                {blockcainType === 'Polygon' || blockcainType === 'Ethereum'
                  ? (
                    <MetamaskWallet
                  onConnect={(user) => this.updateEthereumWallet(user)}
                  onInitAddress={(user) => this.updateAddress(user)}
                  mode="transfer" />
                  )
                  : (
                    <FlowWallet
                  onWalletConnection={(user) => this.updateWallet(user)}
                  onLogInAttempt={() => this.onLogInAttempt()}
                  onInitAddress={(user) => this.updateAddress(user)}
                  mode="transfer" />
                  )}
              </div>
            )
            : (
              <div></div>
            )}
          {nft.contractAddress && walletAddress && !transferSuccess
            ? (
              <div className="form-cta">
                {blockcainType === 'Flow'
                  ? (
                    <button
                    className="btn"
                    type="button"
                    onClick={() => this.createCollection()}>
                      Transfer Digital Asset
                    </button>
                  )
                  : (
                    <button
                    className="btn"
                    type="button"
                    onClick={() => this.initTransfer()}>
                      Transfer Digital Asset
                    </button>
                  )}
                &nbsp;&nbsp;
                <button
                className="btn"
                type="button"
                onClick={() => onClose()}>
                  Close
                </button>
              </div>
            )
            : (
              <div className={transferSuccess ? 'form-cta -align-center' : 'form-cta'}>
                <button
                className="btn"
                type="button"
                onClick={() => onClose(true)}>
                  Close
                </button>
              </div>
            )}
        </SimpleModal>
      </div>
    );
  }
}

TransferWallet.propTypes = {
  nft: PropTypes.string,
  onClose: PropTypes.func,
  // onTransferToUser: propTypes.func,
  onSuccess: PropTypes.func,
};

export default TransferWallet;
